<template>
  <v-container fluid>
    <v-card class="elevation-5">
      <v-card-title color="primary">
        Cancelled
        <v-spacer></v-spacer>
        <v-text-field
          single-line
          hide-details
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          class="mr-12"
        ></v-text-field>
        <div class="ml-10">&nbsp;</div>
      </v-card-title>
      <v-dialog
        persistent
        retain-focus
        refs="scannerDialog"
        v-model="scannerFormDialog"
        max-width="750px"
      >
        <booking-show
          v-model="scannerFormValid"
          :booking="newPending"
          @cancel.stop="cancelPendingForm"
          @save.stop="submitPendingForm"
        ></booking-show>
      </v-dialog>
      <v-data-table
        :headers="headers"
        :items="pendings"
        :custom-filter="filterCustom"
        :loading="loadingNextRecord"
        :footer-props="footerProps"
        item-key="id"
        :items-per-page="10"
      >
        <template v-slot:item.fullname="{ item }">
          <p>{{item.personalInfo.lname}}, {{item.personalInfo.fname}} {{item.personalInfo.mname}}</p>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editPending(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deletePending(item)" color="red">mdi-delete</v-icon>
        </template>
      </v-data-table>
    </v-card>
    <v-snackbar
      top
      right
      v-model="snackbar"
      :timeout="3000"
      :color="snackbarColor"
    >
      {{ snackbarText }}
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import { Firestore } from "../plugins/firebaseInit";
import debounce from "debounce";
import BookingShow from '../components/bookingShow.vue';
export default {
  name: "Cancelled",
  components: { BookingShow },
  created() {
    this.firestore = Firestore
      .collection("bookings")
      .where("status", "==", "CANCELLED")
      .orderBy("date", "asc")
      .onSnapshot(snapshot => {
        let entries = [];
        snapshot.forEach(doc => {
          let data = doc.data();
          data.id = doc.id;
          entries.push(data);
        });
        this.pendings = entries;
        this.cachePendings = entries;
      });
  },
  destroyed: function() {
    this.firestore();
  },
  data() {
    return {
      loadingNextRecord: false,
      currentPage: 1,
      firestore: null,
      firestoreDashboard: null,
      stats: { scanners: 0 },
      footerProps: {
        disableItemsPerPage: true
      },
      snackbar: false,
      snackbarText: "",
      snackbarColor: "info",
      search: "",
      scannerFormValid: false,
      scannerFormMode: "",
      pendings: [],
      cachePendings: [],
      lastPending: null,
      newPending: {
        username: "",
        password: "",
        name: "",
        canAddCategory: false
      },
      defaultPending: {
        username: "",
        password: "",
        name: "",
        canAddCategory: false
      },
      scannerFormDialog: false,
      headers: [
        {
          text: "Reference No.",
          align: "start",
          sortable: false,
          value: "id"
        },
        {
          text: "Date",
          sortable: true,
          value: "date"
        },
        {
          text: "Fullname",
          sortable: true,
          value: "fullname"
        },
        { text: "No of Guest", value: "guestNum" },
        { text: "Amount", value: "total" },
        { text: "Email Status", value: "emailStatus" },
        { text: "", value: "actions" }
      ]
    };
  },
  watch: {
   "search": {
      handler(val) {
        debounce(() => {
          let vm = this;
          if (val.length > 2) {
            vm.loadingNextRecord = true;
            Firestore
              .collection("scanners")
              .where("keywords", "array-contains", val.toLowerCase())
              .limit(20)
              .get()
              .then(snapshot => {
                let entries = [];
                snapshot.forEach(doc => {
                  let data = doc.data();
                  data.id = doc.id;
                  entries.push(data);
                });
                this.lastPending = snapshot.docs[snapshot.docs.length - 1];
                if (entries.length > 0) {
                  vm._fillPendingsData(entries);
                } else {
                  Firestore
                    .collection("scanners")
                    .doc(val)
                    .get()
                    .then(doc => {
                      if (doc.exists) {
                        let data = doc.data();
                        data.id = doc.id;
                        vm._fillPendingsData([data]);
                      } else {
                        vm._fillPendingsData([]);
                      }
                    });
                }
              });
          } else {
            this._getCollection();
          }
        }, 300)();
      }
    }
  },
  methods: {
    _fillPendingsData(entries) {
      this.scanners = entries;
      this.cachePendings = entries;
      this.currentPage = 1;
      this.loadingNextRecord = false;
    },
    cancelPendingForm() {
      this.scannerFormDialog = false;
    },
    editPending(item) {
      this.newPending = item;
      this.scannerFormDialog = true;
    },
    deletePending(item) {
      if (window.confirm("Do you want to delete this item?")) {
        Firestore
          .collection("bookings")
          .doc(item.id)
          .delete()
          .then(() => {
            this.snackbarText = "Cancelled deleted";
            this.snackbarColor = "success";
            this.snackbar = true;
          })
          .catch(error => {
            this.snackbarText = error;
            this.snackbarColor = "error";
            this.snackbar = true;
          });
      }
    },
    _createKeywords(name) {
      const arrName = [];
      let curName = "";
      name
        .toLowerCase()
        .split("")
        .forEach(letter => {
          curName += letter;
          if (curName.length > 2) {
            arrName.push(curName);
          }
        });
      return arrName;
    },
    filterCustom(value, search, item) {
      let fullname =
          this.getFullName(item)
            .toLowerCase()
            .indexOf(search) !== -1,
        address = item.address.toLowerCase().indexOf(search) !== -1,
        nationality = item.nationality.toLowerCase().indexOf(search) !== -1,
        sex = item.sex.toLowerCase().indexOf(search) !== -1;
      return fullname || address || nationality || sex;
    },
  }
};
</script>
