<template>
  <v-card>
    <v-card-title class="pb-0">
      <span class="headline">Booking View</span>
    </v-card-title>
    <v-card-text class="py-1">
      <v-row no-gutters class="py-2">
        <v-col md="3" sm="8">
          <span class="body">Booking Status:</span>
        </v-col>
        <v-col md="3" sm="8">
          <b>{{ booking.status }}</b>
        </v-col>
        <v-col md="3" sm="8">
          <span class="body">Email Status:</span>
        </v-col>
        <v-col md="3" sm="8">
          <b>{{ booking.emailStatus }}</b>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row no-gutters class="pt-2">
        <v-col md="2" sm="8">
          <span class="body">Reference No:</span>
        </v-col>
        <v-col md="6" sm="8">
          <b>{{ booking.id }}</b>
        </v-col>
      </v-row>
      <v-row no-gutters class="">
        <v-col md="2" sm="8">
          <span class="body">Date:</span>
        </v-col>
        <v-col md="6" sm="8">
          <b>{{ booking.date }}</b>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col md="2" sm="8">
          <span class="body">Fullname:</span>
        </v-col>
        <v-col md="6" sm="8">
          <b>{{booking.personalInfo.lname}}, {{booking.personalInfo.fname}} {{booking.personalInfo.mname}}</b>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col md="2" sm="8">
          <span class="body">Address:</span>
        </v-col>
        <v-col md="6" sm="8">
          <b>{{booking.personalInfo.address}}</b>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col md="2" sm="8">
          <span class="body">Contact No:</span>
        </v-col>
        <v-col md="4" sm="8">
          <b>{{booking.personalInfo.contact_number}}</b>
        </v-col>
        <v-col md="2" sm="8">
          <span class="body">Email:</span>
        </v-col>
        <v-col md="4" sm="8">
          <b>{{booking.personalInfo.email}}</b>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col md="2" sm="8">
          <span class="body">Guest Number:</span>
        </v-col>
        <v-col md="6" sm="8">
          <b>{{booking.guestNum}}</b>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col md="2" sm="8">
          <span class="body">Services:</span>
        </v-col>
        <v-col md="6" sm="8">
          <p class="mb-0" v-for="service in booking.services" :key="service.name">
            <b>{{service.name}}</b>
          </p>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col md="2" sm="8">
          <span class="body">Total:</span>
        </v-col>
        <v-col md="6" sm="8">
          <b>{{booking.total}}</b>
        </v-col>
      </v-row>
    </v-card-text>
     <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        v-if="canConfirm"
        :loading="updatingConfirm"
        :color="theme.color + ' darken-1'"
        @click="confirmBooking"
      >
        Confirm Booking
      </v-btn>
      <v-btn
        v-if="canCancel"
        :loading="updatingCancel"
        :color="theme.color + ' darken-1'"
        @click="cancelBooking"
      >
        Cancel Booking
      </v-btn>
      <v-btn @click.stop="$emit('cancel', $event)">
        Close
      </v-btn>
    </v-card-actions>
  </v-card>            
</template>
<script>
import { appConfigs } from "../plugins/appConfigs";
import { Firestore } from "../plugins/firebaseInit";
export default {
  name: "BookingShow",
  props: {
    booking: Object
  },
  data() {
    return {
      theme: appConfigs.theme,
      updatingCancel: false,
      updatingConfirm: false,
    }
  },
  computed: {
    canCancel() {
      return this.booking.status == "PENDING"
    },
    canConfirm() {
      return this.booking.status == "PENDING"
    }
  },
  methods: {
    cancelBooking(event) {
      let vm = this;
      this.updatingCancel = true;
      Firestore.doc(`bookings/${this.booking.id}`).set(
        {
          status: "CANCELLED"
        },
        { merge: true }
      ).then( () => {
        vm.updatingCancel = false;
        vm.$emit('cancel', event);
      })
    },
    confirmBooking(event) {
      let vm = this;
      this.updatingConfirm = true;
      Firestore.doc(`bookings/${this.booking.id}`).set(
        {
          status: "CONFIRMED"
        },
        { merge: true }
      ).then( () => {
        this.booking.status = "CONFIRMED"
        Firestore.doc(`confirmedBookings/${this.booking.id}`).set(
          this.booking
        );
        vm.updatingConfirm = false;
        vm.$emit('cancel', event);
      })
    }
  }
}
</script>